import React from "react"
import { SeoContext } from "./src/hooks/seo"
import { CountersContext } from "./src/hooks/counters"

export const wrapPageElement = ({ element, props }) => {
  const { seo, counters } = props.pageContext.meta || {};
  return (
    <SeoContext.Provider value={seo}>
      <CountersContext.Provider value={counters}>
        {element}
      </CountersContext.Provider>
    </SeoContext.Provider>
  );
}

export const shouldUpdateScroll = (...args) => {
  window.scrollTo(0, 0);
  return [0, 0];
};
