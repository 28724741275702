import React, { useContext } from "react"

export type Counters = {
  yandexMetricaId?: number
  googleAnalytics?: string
  facebookPixel?: string
  vkPixel?: string
  linkedin?: string
}

export enum KnownEvents {
  FormFill,
  SubmitPhone, // sole input submit
  SubmitPhone1, // 1 / 2 inputs submit
  SubmitPhone2, // 2 / 2 inputs submit
  PhoneConfirmSubmit,
  OpenCalendly,
  Success,
}

export const CountersContext = React.createContext<Counters>({})

export const useCounters = () => {
  return useContext(CountersContext)
}

const tryExec = (func: () => {}) => {
  try {
    func()
  } catch (e) {}
}

export const useReportEvent = () => {
  const { yandexMetricaId } = useContext(CountersContext)

  return (eventType: KnownEvents) => {
    // @ts-ignore
    const {
      ym,
      gtag,
      fbq,
      lintrk
    }: { ym: Function; gtag: Function; fbq: Function, lintrk: Function } = window
    switch (eventType) {
      case KnownEvents.OpenCalendly:
        tryExec(() => lintrk("track", { conversion_id: 11817452 }));
        return;
      case KnownEvents.FormFill:
        tryExec(() => ym(yandexMetricaId, "reachGoal", "fill_form"));
        tryExec(() => gtag("event", "fill_form", { event_category: "form" }));
        return;

      case KnownEvents.SubmitPhone:
        tryExec(() => ym(yandexMetricaId, "reachGoal", "click_phone"));
        tryExec(() =>
          gtag("event", "click_phone", { event_category: "button" })
        );
        return

      case KnownEvents.SubmitPhone1:
        tryExec(() => ym(yandexMetricaId, "reachGoal", "click_phone_1"))
        tryExec(() =>
          gtag("event", "click_phone_1", { event_category: "button" })
        )
        return

      case KnownEvents.SubmitPhone2:
        tryExec(() => ym(yandexMetricaId, "reachGoal", "click_phone_2"))
        tryExec(() =>
          gtag("event", "click_phone_2", { event_category: "button" })
        )
        return

      case KnownEvents.PhoneConfirmSubmit:
        tryExec(() => ym(yandexMetricaId, "reachGoal", "code"))
        tryExec(() => gtag("event", "click_code", { event_category: "button" }))
        return

      case KnownEvents.Success:
        tryExec(() => ym(yandexMetricaId, "reachGoal", "success"))
        tryExec(() =>
          gtag("event", "get_lead", { event_category: "success_page" })
        )
        tryExec(() => fbq("track", "CompleteRegistration"))
        return

      default:
        return
    }
  }
}
