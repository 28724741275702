import React, { useContext } from "react"

export type Seo = {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
  lang?: string
  robots?: string
}

export const SeoContext = React.createContext<Seo>({
  title: "ONDOC"
});

export const useSeo = () => {
  return useContext(SeoContext);
};
